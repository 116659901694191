import {useGeneralApp} from "~/stores/generalApp.js";
import {useUserStore} from "~/stores/user.js";
import {UseUpdateData} from "~/composables/updateData.js";
import {UseGetData} from "~/composables/getData.js";
import {navigateTo} from "#app";
import {useUpdateUserLogin} from "~/composables/general/updateUserLogin.js";

export const useHandleUpdateUserProfile = async () => {
    const generalApp = useGeneralApp()
    const config = useRuntimeConfig()
    const userStore = useUserStore()
    const checkCustomPlaces = (place) => {
        if (generalApp.customPlacesList) {
            generalApp.setActivePlaces.filter((instance) => instance.name === place)[0].active = true
        }
    }
    const checkNameChanged = () => {
        return (generalApp.newName !== '') && (generalApp.newName !== userStore.testUser.login);
    }
    try {
        if (checkNameChanged()) {
            await fetch(`${config.public.apiBase}/users/update-profile`, {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + userStore.token,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    "name": generalApp.newName
                })
            }).then(async (response) => {
                if (response.status === 200) {
                    let res = await response.json();
                    await useUpdateUserLogin(res)
                    generalApp.newName = ''
                } else {
                    console.log('smth went wrong try again')
                }
            })
        }
        await UseUpdateData('user','work_place', generalApp.newPlace).then(async () => {
            userStore.testUser.place = await UseGetData('user','work_place');
        })
        userStore.newTicket.danger_zone = ''
        generalApp.newPlace = ''
        checkCustomPlaces(userStore.testUser.place)
        navigateTo(`/user/${userStore.testUser.id}`);
    } catch (err) {
        console.log(err, 'err during update user profile')
    }
}